import React, {Suspense, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Login from "./scenes/login";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ColorModeContext, useMode} from "./theme";
import {routes as appRoutes} from "./routes";
import {Spinner} from "reactstrap";
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [reload, setReload] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(!useMediaQuery(theme.breakpoints.up('md')));
    const handleReload = () => {
        setReload(!reload);
    }

    return (<ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className="app">
                {!sessionStorage.getItem("jwt") ? (<Routes>
                    <Route
                        key="login-route"
                        path="/login"
                        element={<Login/>}
                    />
                    <Route
                        key="unmapped"
                        path="*"
                        element={<Navigate to={sessionStorage.getItem("jwt") ? "/" : "/login"}/>}
                    />
                </Routes>) : (<>
                    <Sidebar isSidebar={isSidebar} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>
                    <main className="content" style={{overflowX: "hidden", overflowY: "auto"}}>
                        <Topbar setIsSidebar={setIsSidebar} setIsCollapsed={setIsCollapsed}
                                handleReload={handleReload}/>
                        <Suspense fallback={<Spinner color="primary"/>}>
                            <Routes key={reload}>
                                {appRoutes
                                    .filter((r) => r.enabled)
                                    .map((route) => (<Route
                                        key={route.key}
                                        path={route.path}
                                        element={<route.component/>}
                                    />))}
                            </Routes>
                        </Suspense>
                    </main>
                </>)}
            </div>
        </ThemeProvider>
    </ColorModeContext.Provider>);
}

export default App;
