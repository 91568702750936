import axios from 'axios';
import {getDomain} from './utils/getDomain';

export const api = axios.create({
    baseURL: getDomain(), headers: {
        'Content-Type': 'application/json', 'Authorization': 'Bearer ' + sessionStorage.getItem('jwt')
    }
});

api.interceptors.response.use(function (response) {
    // if (response.data.jwt) sessionStorage.setItem('jwt', response.data.jwt);
    return response;
}, function (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (window.location.pathname !== '/login') {
            sessionStorage.clear();
            window.location.href = '/login';
        }
    }
    return Promise.reject(error);
});

api.interceptors.request.use(function (request) {
    if (!!sessionStorage.getItem("sus_id")) {
        request.url += "/" + sessionStorage.getItem("sus_id");
    }
    return request;
},);

export default api;
