import {Autocomplete, Box, IconButton, TextField, useTheme} from "@mui/material";
import {useContext, useState} from "react";
import {ColorModeContext} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import api from "../../api";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getSuS, isCoach} from "../../utils/JWTTokenExtractor";

const Topbar = ({handleReload, setIsCollapsed}) => {
    const theme = useTheme();
    // const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [susData] = useState(isCoach() && getSuS());
    const [sus, setSus] = useState(isCoach() && Object.values(susData)[0]);
    if (isCoach() && !sessionStorage.getItem("sus_id")) sessionStorage.setItem("sus_id", Object.keys(susData)[0]);


    const handleChange = (event, newValue) => {
        if (newValue === null) return;
        setSus(newValue);
        sessionStorage.setItem("sus_id", Object.keys(susData).find(uuid => susData[uuid] === newValue))
        handleReload();
    };

    // useEffect(() => {
    //     const fetchSus = async () => {
    //         try {
    //             const response = await api.get('/sus');
    //             const tmp = response.data.map(entry => ({
    //                 label: entry.name, id: entry.id,
    //             }));
    //             setSusData(tmp);
    //             setSus(tmp[0]);
    //             sessionStorage.setItem("sus_id", response.data[0].id)
    //         } catch (error) {
    //             alert(error.message);
    //         }
    //     };
    //
    //     if (isCoach()) fetchSus();
    // }, []);

    return (<Box
        justifyContent="space-between"
        p={2}
        top={0}
        position="sticky"
        bgcolor={theme.palette.background.default}
        zIndex={1000}
        boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.06)"
    >
        {useMediaQuery(theme.breakpoints.down(769)) && (
            <IconButton onClick={() => setIsCollapsed((prev) => (!prev))} sx={{
                float: "left",
                mt: isCoach() ? "10px" : undefined
            }}>
                <MenuOutlinedIcon/>
            </IconButton>
        )}
        {/* ICONS */}
        <Box display="flex" justifyContent="flex-end">


            {isCoach() &&
                <Autocomplete
                    disablePortal
                    blurOnSelect
                    options={Object.values(susData)}
                    value={sus}
                    sx={{width: "60%", maxWidth: "300px", mr: "20px"}}
                    onChange={handleChange}
                    renderInput={(params) => <TextField id={params.id} {...params} label="SuS wählen"/>}
                />}
            <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon/> : <LightModeOutlinedIcon/>}
            </IconButton>
            {/*<IconButton component={Link} to="/profil">*/}
            {/*    <PersonOutlinedIcon/>*/}
            {/*</IconButton>*/}
            <IconButton
                type="button"
                sx={{p: 1}}
                onClick={() => {
                    api.post("/logout");
                    sessionStorage.clear();
                    window.location.href = "/login";
                }}
            >
                <LogoutIcon/>
            </IconButton>
        </Box>

    </Box>);
};

export default Topbar;
