import api from '.';

export interface LoginResponse {
    access_token: string;
    token_type: string;
}

export const login = async (username: string, password: string): Promise<LoginResponse> => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    const response = await api.post<LoginResponse>('/login', params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    sessionStorage.setItem('jwt', response.data.access_token);
    return response.data;
};

export const logout = async (): Promise<void> => {
    await api.post('/logout');
    sessionStorage.clear();
};
