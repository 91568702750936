import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
        .then(function(registration) {
            console.log('Service Worker registriert mit Scope:', registration.scope);
        })
        .catch(function(error) {
            console.log('Service Worker Registrierung fehlgeschlagen:', error);
        });
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);

// serviceWorkerRegistration.register();
