import {useState} from "react";
import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import {tokens} from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import AlarmIcon from "@mui/icons-material/Alarm";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getName, isCoach} from "../../utils/JWTTokenExtractor";

const Item = ({title, to, icon, selected, setSelected, setIsCollapsed}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const mobile = useMediaQuery(theme.breakpoints.down(769));
    return (<MenuItem
        active={selected.toLowerCase() === title.toLowerCase()}
        style={{
            color: colors.grey[100],
        }}
        onClick={() => {
            setSelected(title);
            mobile && setIsCollapsed(true);
        }}
        icon={icon}
        component={<Link to={to}/>}
    >
        <Typography>{title}</Typography>
    </MenuItem>);
};

const App = ({isCollapsed, setIsCollapsed}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState(window.location.pathname.substring(1) ? window.location.pathname.substring(1) : "Dashboard");
    const mobile = useMediaQuery(theme.breakpoints.down(769));

    return (<Box
        sx={{
            "& .ps-sidebar-root": {
                background: `${colors.primary[400]} !important`,
                mt: mobile ? "70px!important" : undefined
            }, "& .ps-sidebar-container": {
                backgroundColor: "transparent !important",
            }, "& .ps-menu-button": {
                padding: "5px 35px 5px 20px !important",
            }, "& .css-1l8icbj > .ps-menuitem-root > .ps-menu-button:hover": {
                color: "#868dfb !important",
                backgroundColor: `${colors.blueAccent[800]} !important`
            }, "& .ps-active": {
                color: "#6870fa !important",
            }, "& .css-1t8x7v1 > .ps-menu-button:hover": {
                backgroundColor: `transparent !important`
            }, " & .css-1wvake5.ps-broken": {
                left: "0",
            },
            display: "flex",
        }}

    >
        <Sidebar collapsed={isCollapsed} breakPoint="md">
            <Menu iconShape="square">
                <MenuItem
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    icon={isCollapsed ? <MenuOutlinedIcon/> : undefined}
                    style={{
                        margin: "10px 0 20px 0", color: colors.grey[100],
                    }}
                >
                    {!isCollapsed && (<Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h3"
                            color={colors.grey[100]}
                        >
                            Fitness Journey
                        </Typography>
                        {!mobile && (
                            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                <MenuOutlinedIcon/>
                            </IconButton>)}
                    </Box>)}
                </MenuItem>

                {!isCollapsed && (<Box mb="25px">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            alt="Logo"
                            height="100px"
                            src="logo512.png"
                            style={{borderRadius: "50%"}}
                        />
                    </Box>
                    <Box textAlign="center">
                        <Typography
                            variant="h2"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{m: "10px 0 0 0"}}
                        >
                            {getName()}
                        </Typography>
                        <Typography
                            variant="h5"
                            color={colors.greenAccent[500]}
                        >
                            {isCoach() ? "Lehrperson" : "SchülerIn"}
                        </Typography>
                    </Box>
                </Box>)}

                <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                    <Item
                        title="Dashboard"
                        to="/"
                        icon={<HomeOutlinedIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                    {/*{isCoach()(*/}
                    {/*    <Box>*/}
                    {/*        <Typography*/}
                    {/*            variant="h6"*/}
                    {/*            color={colors.grey[300]}*/}
                    {/*            sx={{m: "15px 0 5px 20px"}}*/}
                    {/*        >*/}
                    {/*            Coach-Panels*/}
                    {/*        </Typography>*/}
                    {/*        <Item*/}
                    {/*            title="Kontaktinformationen"*/}
                    {/*            to="/kontakte"*/}
                    {/*            icon={<ContactsOutlinedIcon/>}*/}
                    {/*            selected={selected}*/}
                    {/*            setSelected={setSelected}*/}
                    {/*            setIsCollapsed={setIsCollapsed}*/}
                    {/*        />*/}
                    {/*        <Item*/}
                    {/*            title="Fortschritte"*/}
                    {/*            to="/fortschritte"*/}
                    {/*            icon={<ReceiptOutlinedIcon/>}*/}
                    {/*            selected={selected}*/}
                    {/*            setSelected={setSelected}*/}
                    {/*            setIsCollapsed={setIsCollapsed}*/}
                    {/*        />*/}
                    {/*    </Box>)}*/}
                    <Typography
                        variant="h6"
                        color={colors.grey[300]}
                        sx={{m: "15px 0 5px 20px"}}
                    >
                        Daten
                    </Typography>
                    <Item
                        title="Kalender"
                        to="/kalender"
                        icon={<CalendarTodayOutlinedIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Item
                        title="Training"
                        to="/training"
                        icon={<BarChartOutlinedIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Item
                        title="Schlaf"
                        to="/schlaf"
                        icon={<AlarmIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Item
                        title="Gewicht"
                        to="/gewicht"
                        icon={<TimelineOutlinedIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                    <Typography
                        variant="h6"
                        color={colors.grey[300]}
                        sx={{m: "15px 0 5px 20px"}}
                    >
                        Sonstiges
                    </Typography>
                    <Item
                        title="Über Fijo"
                        to="/faq"
                        icon={<HelpOutlineOutlinedIcon/>}
                        selected={selected}
                        setSelected={setSelected}
                        setIsCollapsed={setIsCollapsed}
                    />
                </Box>
            </Menu>
        </Sidebar>
    </Box>);
};

export default App;
